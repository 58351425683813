import React, { createContext, useContext, useEffect, useState } from 'react'
import { ILang } from '@kansonar-exam-front/graphql'
import { useSearchParams } from 'react-router-dom'

interface ILanguageContextProps {
  language: ILang
  changeLanguage: (lng: ILang) => void
}

export const LanguageContext = createContext<ILanguageContextProps>({
  language: ILang.RU,
  changeLanguage: () => {},
})

export function LanguageProvider({ children }: React.PropsWithChildren) {
  const [query, setQuery] = useSearchParams()
  const [language, setLanguage] = useState<ILang>(ILang.RU)

  useEffect(
    () => {
      const lang = window.localStorage.getItem('_lang')

      if (lang && lang !== language) {
        setLanguage(lang as ILang)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const changeLanguage = (lng: ILang) => {
    setLanguage(lng)
    window.localStorage.setItem('_lang', lng)

    // Reset pagination
    if (query.get('page')) {
      query.delete('page')
      setQuery(query)
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
