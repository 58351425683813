import { action, computed, makeObservable, observable } from 'mobx'
import { IUser } from '@kansonar-exam-front/graphql'

export class AuthStore {
  public user: IUser | null = null

  public token: string | null = null

  public loading = true

  constructor() {
    makeObservable(this, {
      user: observable,
      token: observable,
      loading: observable,
      isLoggedIn: computed,
      setUser: action,
      setToken: action,
      setLoading: action,
    })

    this.loadFromStorage()
  }

  get isLoggedIn() {
    return !!this.user && !!this.token
  }

  setUser(user: IUser | null) {
    this.user = user
  }

  updateUser(user: IUser) {
    this.setUser(user)
    this.saveToStorage()
  }

  setToken(token: string | null) {
    this.token = token
  }

  getToken() {
    return this.token
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  login(user: IUser, token: string, remember?: boolean) {
    this.setUser(user)
    this.setToken(token)

    if (remember) {
      this.saveToStorage()
    }
  }

  logout() {
    this.setUser(null)
    this.setToken(null)

    this.clearStorage()
  }

  private saveToStorage() {
    const data = {
      token: this.token,
    }

    window.localStorage.setItem('@auth', JSON.stringify(data))
  }

  private clearStorage() {
    window.localStorage.removeItem('@auth')
  }

  private loadFromStorage() {
    const data = window.localStorage.getItem('@auth')

    if (data) {
      const { token } = JSON.parse(data)

      this.setToken(token)
    }
  }
}
export default new AuthStore()
