import { BrowserRouter } from 'react-router-dom'
import { UIKitProvider } from '@kansonar-exam-front/ui-kit'
import { ApolloProvider, createApolloClient } from '@kansonar-exam-front/graphql'

import { useStores } from './stores/hooks'
import { WatchUser } from './layout/WatchUser'
import Router from './Router'
import { LanguageProvider } from './contexts'

function App() {
  const { authStore } = useStores()

  const apolloClient = createApolloClient({
    apiUrl: process.env.REACT_APP_API_URL || '',
    getToken: () => authStore.token ?? '',
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
          if (extensions && extensions.code === 'INVALID_TOKEN') {
            authStore.logout()
          }

          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        })
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    },
  })

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <UIKitProvider>
          <LanguageProvider>
            <WatchUser />
            <Router />
          </LanguageProvider>
        </UIKitProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default App
