import React, { PropsWithChildren, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'

import { useStores } from '../stores/hooks'
import Login from '../pages/auth/Login'

export const AuthGuard: React.FC<PropsWithChildren> = observer(({ children }) => {
  const { authStore } = useStores()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  if (!authStore.isLoggedIn) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }

    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)

    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
})
