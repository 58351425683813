import { useRef, useState } from 'react'
import { alpha } from '@mui/material/styles'
import { Box, MenuItem, Stack, IconButton, Typography } from '@mui/material'
import { MenuPopover } from '@kansonar-exam-front/ui-kit'
import { ILang } from '@kansonar-exam-front/graphql'

import { useLanguage } from '../../contexts'

const LANGS = [
  {
    value: ILang.RU,
    label: 'Русский',
    icon: 'https://raw.githubusercontent.com/hampusborgos/country-flags/main/png100px/ru.png',
  },
  {
    value: ILang.KK,
    label: 'Казакша',
    icon: 'https://raw.githubusercontent.com/hampusborgos/country-flags/main/png100px/kz.png',
  },
]

export function LanguagePopover() {
  const { language, changeLanguage } = useLanguage()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (lng: ILang) => {
    changeLanguage(lng)
    handleClose()
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Typography variant='subtitle1' color='grey.500'>
          {language.toUpperCase()}
        </Typography>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === language}
              onClick={() => handleChange(option.value)}
            >
              <Box component='img' alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
