import { ElementType, lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import DashboardLayout from './layout/dashboard'
import AuthLayout from './layout/AuthLayout'
import { AuthGuard, GuestGuard } from './guards'

function Router() {
  return (
    <Routes>
      <Route
        path='auth'
        element={
          <GuestGuard>
            <AuthLayout />
          </GuestGuard>
        }
      >
        <Route index element={<Navigate to='/auth/login' replace />} />
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
      </Route>
      <Route
        path='/'
        element={
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        }
      >
        <Route index element={<Navigate to='/dashboard' replace />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='profile' element={<Profile />} />

        <Route path='users'>
          <Route index element={<Navigate to='/users/list' replace />} />
          <Route path='list' element={<Users />} />
          <Route path=':id' element={<UserDetails />} />
          <Route path='edit-user/:id' element={<UpdateUserData />} />

          <Route path='edit-profile/:id' element={<UpdateUserProfile />} />
        </Route>

        <Route path='exams'>
          <Route index element={<Navigate to='/exams/list' replace />} />
          <Route path='list' element={<Exams />} />
          <Route path=':id' element={<Exam />} />
        </Route>

        <Route path='/calendar' element={<Calendar />} />

        <Route path='lessons'>
          <Route index element={<Navigate to='/lessons/list' replace />} />
          <Route path='list' element={<Lessons />} />
          <Route path='create' element={<CreateLesson />} />
          <Route path='edit/:id' element={<EditLesson />} />
        </Route>

        <Route path='questions' element={<Questions />} />
      </Route>

      <Route path='*'>
        <Route path='404' element={<NotFound />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Route>
    </Routes>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box p={2} textAlign='center'>
          <CircularProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  )

const NotFound = Loadable(lazy(() => import('./pages/Page404')))
const Login = Loadable(lazy(() => import('./pages/auth/Login')))
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('./pages/auth/ResetPassword')))
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')))
const Lessons = Loadable(lazy(() => import('./pages/lessons/Lessons')))
const CreateLesson = Loadable(lazy(() => import('./pages/lessons/CreateLesson')))
const EditLesson = Loadable(lazy(() => import('./pages/lessons/EditLesson')))
const Questions = Loadable(lazy(() => import('./pages/questions/Questions')))
const Users = Loadable(lazy(() => import('./pages/users/Users')))
const UserDetails = Loadable(lazy(() => import('./pages/users/UserDetails')))
const Exams = Loadable(lazy(() => import('./pages/exams/Exams')))
const Exam = Loadable(lazy(() => import('./pages/exams/Exam')))
const Calendar = Loadable(lazy(() => import('./pages/calendar/Calendar')))
const UpdateUserData = Loadable(lazy(() => import('./pages/users/UpdateUserData')))
const UpdateUserProfile = Loadable(lazy(() => import('./pages/users/UpdateUserProfile')))
const Profile = Loadable(lazy(() => import('./pages/profile/Profile')))

export default Router
