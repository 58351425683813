import { Stack, TextField, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useIsMountedRef } from '@kansonar-exam-front/ui-kit'
import { IUser, useLoginAdminMutation } from '@kansonar-exam-front/graphql'
import { Form, FormikProvider, useFormik } from 'formik'

import { useStores } from '../../stores/hooks'

interface IFormValues {
  email: string
  password: string
  afterSubmit?: string
}

export function LoginForm() {
  const isMountedRef = useIsMountedRef()
  const { authStore } = useStores()
  const [loginAdmin] = useLoginAdminMutation()

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email должен быть действительным адресом электронной почты')
      .required('Email обязателен'),
    password: Yup.string().required('Пароль обязателен'),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { data } = await loginAdmin({
          variables: {
            email: values.email,
            password: values.password,
          },
        })

        if (data?.loginAdmin) {
          authStore.login(data.loginAdmin.user as IUser, data.loginAdmin.token, true)
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete='username'
            type='email'
            label='Email'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete='current-password'
            type='password'
            label='Пароль'
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton type='submit' size='large' variant='contained' loading={isSubmitting}>
            Войти
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
