import { Iconify } from '@kansonar-exam-front/ui-kit'

// ----------------------------------------------------------------------

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    title: 'Главная',
    path: '/dashboard',
    icon: getIcon('bx:home-alt-2'),
  },
  {
    title: 'Пользователи',
    path: '/users',
    icon: getIcon('eva:people-outline'),
  },
  {
    title: 'Экзамены',
    path: '/exams',
    icon: getIcon('eva:checkmark-square-2-outline'),
  },
  {
    title: 'Календарь экзаменов',
    path: '/calendar',
    icon: getIcon('eva:calendar-outline'),
  },
  {
    title: 'Учебные материалы',
    path: '/lessons',
    icon: getIcon('eva:book-outline'),
  },
  {
    title: 'Управление вопросами',
    path: '/questions',
    icon: getIcon('eva:list-outline'),
  },
]

export default navConfig
