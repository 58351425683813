import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Link, Drawer, Typography } from '@mui/material'
import { Scrollbar, useResponsive, NavSection } from '@kansonar-exam-front/ui-kit'

import navConfig from './NavConfig'
import { SidebarAccount } from './SidebarAccount'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))

// ----------------------------------------------------------------------

interface IProps {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: IProps) {
  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Typography variant='h5' color='primary.main'>
          KANSONAR
        </Typography>
        <Typography
          variant='h5'
          color='grey.400'
          fontWeight={(th) => th.typography.fontWeightLight}
        >
          ADMIN
        </Typography>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='/profile'>
          <SidebarAccount />
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.paper',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
