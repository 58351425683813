import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import { MobileDateTimePicker } from '@mui/x-date-pickers'

import { Iconify } from '../Iconify'

interface IProps {
  value: Date | null
  onChange: (value: Date | null) => void
  disablePast?: boolean
}

export function DateTimePicker({ value, onChange, disablePast }: IProps) {
  return (
    <MobileDateTimePicker
      label='Выберите дату и время'
      value={value}
      onChange={onChange}
      openTo='hours'
      disablePast={disablePast}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon='fa:calendar' width={20} height={20} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
