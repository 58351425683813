import React, { useState } from 'react'
import { Box, Button, Dialog, DialogContent, Grid, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'

export interface IDateRangePickerValue {
  startDate: Date
  endDate: Date
}

interface IProps {
  value: IDateRangePickerValue
  open: boolean
  byMonth?: boolean
  onClose: VoidFunction
  onChange: (value: IDateRangePickerValue) => void
}

type TValue = Date | null | undefined

export const DateRangePickerDialog: React.FC<IProps> = ({
  value,
  open,
  byMonth,
  onChange,
  onClose,
}) => {
  const [startDate, setStartDate] = useState<TValue>(value.startDate)
  const [endDate, setEndDate] = useState<TValue>(value.endDate)

  const handleChangeStartDate = (newValue: TValue) => {
    setStartDate(newValue)
  }

  const handleChangeEndDate = (newValue: TValue) => {
    setEndDate(newValue)
  }

  const handleSubmit = () => {
    if (startDate && endDate) {
      onChange({ startDate, endDate })
      onClose()
    }
  }

  return (
    <Dialog open={open} maxWidth={byMonth ? 'xs' : 'sm'} fullWidth onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12} sm={6}>
            <DesktopDatePicker
              label='Начало'
              inputFormat='DD.MM.yyyy'
              value={startDate}
              onChange={handleChangeStartDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DesktopDatePicker
              label='Конец'
              inputFormat='DD.MM.yyyy'
              value={endDate}
              // minDate={startDate}
              onChange={handleChangeEndDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>

        <Box pt={2} textAlign='right'>
          <Button onClick={onClose} color='inherit'>
            Закрыть
          </Button>
          <Button type='submit' variant='contained' sx={{ ml: 1 }} onClick={handleSubmit}>
            Применить
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
