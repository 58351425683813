import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import moment from 'moment'

import { DateRangePickerDialog, IDateRangePickerValue } from './DateRangePickerDialog'

import { Iconify } from '../Iconify'

interface IProps {
  value: IDateRangePickerValue
  onChange: (value: IDateRangePickerValue) => void
  byMonth?: boolean
}

export const DateRangePicker: React.FC<IProps> = ({ value, onChange, byMonth }) => {
  const [open, setOpen] = useState(false)

  const toggleDialog = () => {
    setOpen((prev) => !prev)
  }

  return (
    <Box>
      <Button
        variant='outlined'
        sx={{ width: { md: 260, sm: undefined } }}
        startIcon={<Iconify icon={'ion:calendar-outline'} />}
        endIcon={<Iconify icon={'ic:keyboard-arrow-down'} />}
        onClick={toggleDialog}
      >
        {`${moment(new Date(value.startDate), 'dd.MM.yyyy').format('DD.MM.YYYY')} - ${moment(
          new Date(value.endDate),
          'dd.MM.yyyy'
        ).format('DD.MM.YYYY')}`}
      </Button>
      {open && (
        <DateRangePickerDialog
          open
          onClose={toggleDialog}
          value={value}
          byMonth={byMonth}
          onChange={onChange}
        />
      )}
    </Box>
  )
}
