import { Avatar, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'

import { useStores } from '../../stores/hooks'

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}))

export const SidebarAccount = observer(() => {
  const { authStore } = useStores()
  const { user } = authStore

  if (!user) {
    return null
  }

  return (
    <AccountStyle>
      <Avatar alt='photoURL' />
      <Box sx={{ ml: 2 }}>
        <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
          {user.fullName}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Admin
        </Typography>
      </Box>
    </AccountStyle>
  )
})
