import { Link as RouterLink } from 'react-router-dom'
import { Link, Container, Typography, Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import { LoginForm } from '../../sections/auth'

export default function Login() {
  return (
    <RootStyle>
      <Container maxWidth='lg'>
        <ContentStyle>
          <Stack direction='row' mb={3}>
            <Typography
              variant='h3'
              color='primary.main'
              fontWeight={(th) => th.typography.fontWeightBold}
            >
              KANSONAR
            </Typography>
            <Typography
              variant='h3'
              color='grey.400'
              fontWeight={(th) => th.typography.fontWeightLight}
            >
              ADMIN
            </Typography>
          </Stack>
          <Typography variant='h4' mb={3}>
            Вход
          </Typography>

          <LoginForm />

          <Box textAlign='right' pt={3}>
            <Link component={RouterLink} to='/auth/forgot-password'>
              Забыли пароль?
            </Link>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))
