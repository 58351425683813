import { useNavigate } from 'react-router-dom'
import { Stack, TextField, Typography, Card, Box, CardContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import {
  IUpdateUserDataByAdminInput,
  IUser,
  refetchUserByAdminQuery,
  useUpdateUserDataByAdminMutation,
} from '@kansonar-exam-front/graphql'
import { PhoneField, useIsMountedRef } from '@kansonar-exam-front/ui-kit'
import { Form, FormikProvider, useFormik } from 'formik'

interface IFormValues extends IUpdateUserDataByAdminInput {
  afterSubmit?: string
}

interface IProps {
  user: IUser
}

export function UpdateUserByAdminForm({ user }: IProps) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()

  const [updateUser] = useUpdateUserDataByAdminMutation()

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    middleName: Yup.string(),
    phone: Yup.string().required(),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      middleName: user?.middleName || '',
      phone: user?.phone || '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const { data } = await updateUser({
          variables: {
            userId: user.id,
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              middleName: values.middleName,
              phone: values.phone,
            },
          },
          refetchQueries: [refetchUserByAdminQuery({ id: user.id })],
        })

        if (data?.updateUserDataByAdmin) {
          enqueueSnackbar('Данные успешно сохранены!', { variant: 'success' })
          navigate(`/users/${user.id}`, { replace: true })
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  if (!user) {
    return null
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Box>
                <Typography variant='subtitle1'>{user.fullName}</Typography>
                <Typography variant='body2' color='text.secondary'>
                  {user.email}
                </Typography>
              </Box>
              <TextField
                fullWidth
                autoComplete='username'
                label='Фамилия'
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
              <TextField
                fullWidth
                autoComplete='username'
                label='Имя'
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <TextField
                fullWidth
                autoComplete='username'
                label='Отчество'
                {...getFieldProps('middleName')}
                error={Boolean(touched.middleName && errors.middleName)}
                helperText={touched.middleName && errors.middleName}
              />
              <PhoneField
                fullWidth
                autoComplete='username'
                label='Номер телефона'
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Stack>
          </CardContent>
        </Card>
        <Box textAlign='center'>
          <LoadingButton
            size='large'
            fullWidth
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  )
}
